
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useAuthenticationStore } from "@/store/pinia/application/useAuthenticationStore";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import {
  swalAlertConfirmation,
  swalAlertError,
  swalAlertSuccess,
} from "@/core/helpers/sweet-alert";

export default defineComponent({
  name: "password-reset",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const authStore = useAuthenticationStore();
    const step = ref("input-email");
    const isPasswordSame = ref(false);

    const submitButton = ref<HTMLElement | null>(null);

    //Create form validation object
    const forgotPassword = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
    });

    const handleChangeInputCode = (val) => {
      authStore.stateForgotSession.token = `${val.replace(/[^0-9]/, "")}`;
    };

    const handleConfirmationPasswordInput = (val) => {
      if (authStore.stateForgotSession.newPassword == val) {
        authStore.stateForgotSession.newPasswordConf = val;
        isPasswordSame.value = true;
      }
    };

    //Form submit function
    const onSubmitForgotPassword = () => {
      if (step.value == "input-email") {
        swalAlertConfirmation(
          `Kirim kode verifikasi ke email aktif ?`,
          "Konfirmasi"
        ).then(async (res) => {
          if (res.isConfirmed) {
            authStore.forgotPassword().then(() => {
              if (!authStore.isError) {
                step.value = "input-token";
              } else {
                swalAlertError(`Gagal melakukan lupa password`, "Close");
              }
            });
          }
        });
      } else if (step.value == "input-token") {
        swalAlertConfirmation(
          `Pastikan kode verifikasi sudah benar ?`,
          "Konfirmasi"
        ).then(async (res) => {
          if (res.isConfirmed) {
            authStore.forgotPasswordValidate().then(() => {
              if (!authStore.isError) {
                step.value = "input-new-password";
              } else {
                swalAlertError(
                  `Kode konfirmasi salah!\nPeriksa kembali kode pada email anda`,
                  "Close"
                );
              }
            });
          }
        });
      } else if (step.value == "input-new-password") {
        if (
          authStore.stateForgotSession.newPassword !==
          authStore.stateForgotSession.newPasswordConf
        ) {
          swalAlertError(`Konfirmasi password belum sama!`, "Close");
          return;
        }
        swalAlertConfirmation(
          `Pastikan password sudah sama ?`,
          "Konfirmasi"
        ).then(async (res) => {
          if (res.isConfirmed) {
            authStore.forgotPasswordReset().then(() => {
              if (!authStore.isError) {
                step.value = "complete-forgot";
              } else {
                swalAlertError(
                  `Gagal mengubah password.\nPastikan password alfanumerik dan mengandung minimal 1 simbol.`,
                  "Close"
                );
              }
            });
          }
        });
      } else {
        router.push("/sign-in");
      }
    };

    return {
      authStore,
      onSubmitForgotPassword,
      forgotPassword,
      submitButton,
      step,
      handleChangeInputCode,
      handleConfirmationPasswordInput,
      isPasswordSame,
    };
  },
});
