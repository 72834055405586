<template>
  <!--begin::Wrapper-->
  <div class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
    <!--begin::Form-->
    <Form
      class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      @submit.prevent
      id="kt_login_password_reset_form"
      :validation-schema="forgotPassword"
    >
      <!--begin::Heading-->
      <div class="text-center mb-6">
        <!--begin::Title-->
        <h1 class="text-dark mb-3">Lupa Password ?</h1>
      </div>
      <!--begin::Heading-->

      <!--begin::Input group-->
      <template v-if="step == 'input-email'">
        <div class="text-gray-400 fw-bold fs-4 mb-10 text-center">
          Masukkan email akun untuk mereset password.
        </div>
        <div class="fv-row mb-10">
          <label class="form-label fw-bolder text-gray-900 fs-6">Email</label>
          <Field
            class="form-control form-control-solid"
            type="email"
            placeholder=""
            name="email"
            autocomplete="off"
            v-model="authStore.stateForgotSession.email"
          />
          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <ErrorMessage name="email" />
            </div>
          </div>
        </div>
      </template>

      <!--end::Input group-->
      <template v-if="step == 'input-token'">
        <div class="text-gray-400 fw-bold fs-4 mb-10 text-center">
          Masukkan kode yang telah dikirim ke email anda.
        </div>
        <div class="fv-row mb-10">
          <label class="form-label fw-bolder text-gray-900 fs-6"
            >Enter Verification Code</label
          >
          <el-input
            class="token-input text-center"
            type="email"
            placeholder=""
            name="token"
            autocomplete="off"
            @input="handleChangeInputCode($event)"
            v-model="authStore.stateForgotSession.token"
          ></el-input>
        </div>
      </template>

      <template v-if="step == 'input-new-password'">
        <div class="text-gray-400 fw-bold fs-4 mb-10 text-center">
          Pastikan password adalah alfanumerik, dan memiliki minimal 1 simbol.
        </div>
        <div class="fv-row mb-10">
          <label class="form-label fw-bolder text-gray-900 fs-6"
            >Password Baru</label
          >
          <el-input
            type="password"
            placeholder=""
            name="password"
            autocomplete="off"
            show-password
            v-model="authStore.stateForgotSession.newPassword"
          />
          <label class="form-label fw-bolder text-gray-900 fs-6 mt-4"
            >Konfirmasi Password</label
          >
          <el-input
            type="password"
            placeholder=""
            name="confpassword"
            autocomplete="off"
            show-password
            v-model="authStore.stateForgotSession.newPasswordConf"
            @input="handleConfirmationPasswordInput($event)"
          />
          <div
            class="fv-plugins-message-container"
            v-if="
              authStore.stateForgotSession.newPassword !== '' && !isPasswordSame
            "
          >
            <div class="fv-help-block">
              <span>Password tidak sama.</span>
            </div>
          </div>
        </div>
      </template>

      <template v-if="step == 'complete-forgot'">
        <div class="text-gray-400 fw-bold fs-4 mb-10 text-center">
          Reset password berhasil!, Silahkan kembali ke halaman login.
        </div>
      </template>

      <!--begin::Actions-->
      <div class="d-flex flex-wrap justify-content-center pb-lg-0">
        <button
          type="submit"
          ref="submitButton"
          @click="onSubmitForgotPassword()"
          id="kt_password_reset_submit"
          class="btn btn-lg btn-primary fw-bolder me-4"
        >
          <span class="indicator-label">
            {{
              step == "complete-forgot" ? "Kembali Ke Halaman Login" : "Submit"
            }}
          </span>
          <span class="indicator-progress">
            Please wait...
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </button>

        <router-link
          v-if="step !== 'complete-forgot'"
          to="/sign-in"
          class="btn btn-lg btn-light-primary fw-bolder"
          >Cancel</router-link
        >
      </div>
      <!--end::Actions-->
    </Form>
    <!--end::Form-->
  </div>
  <!--end::Wrapper-->
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useAuthenticationStore } from "@/store/pinia/application/useAuthenticationStore";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import {
  swalAlertConfirmation,
  swalAlertError,
  swalAlertSuccess,
} from "@/core/helpers/sweet-alert";

export default defineComponent({
  name: "password-reset",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const authStore = useAuthenticationStore();
    const step = ref("input-email");
    const isPasswordSame = ref(false);

    const submitButton = ref<HTMLElement | null>(null);

    //Create form validation object
    const forgotPassword = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
    });

    const handleChangeInputCode = (val) => {
      authStore.stateForgotSession.token = `${val.replace(/[^0-9]/, "")}`;
    };

    const handleConfirmationPasswordInput = (val) => {
      if (authStore.stateForgotSession.newPassword == val) {
        authStore.stateForgotSession.newPasswordConf = val;
        isPasswordSame.value = true;
      }
    };

    //Form submit function
    const onSubmitForgotPassword = () => {
      if (step.value == "input-email") {
        swalAlertConfirmation(
          `Kirim kode verifikasi ke email aktif ?`,
          "Konfirmasi"
        ).then(async (res) => {
          if (res.isConfirmed) {
            authStore.forgotPassword().then(() => {
              if (!authStore.isError) {
                step.value = "input-token";
              } else {
                swalAlertError(`Gagal melakukan lupa password`, "Close");
              }
            });
          }
        });
      } else if (step.value == "input-token") {
        swalAlertConfirmation(
          `Pastikan kode verifikasi sudah benar ?`,
          "Konfirmasi"
        ).then(async (res) => {
          if (res.isConfirmed) {
            authStore.forgotPasswordValidate().then(() => {
              if (!authStore.isError) {
                step.value = "input-new-password";
              } else {
                swalAlertError(
                  `Kode konfirmasi salah!\nPeriksa kembali kode pada email anda`,
                  "Close"
                );
              }
            });
          }
        });
      } else if (step.value == "input-new-password") {
        if (
          authStore.stateForgotSession.newPassword !==
          authStore.stateForgotSession.newPasswordConf
        ) {
          swalAlertError(`Konfirmasi password belum sama!`, "Close");
          return;
        }
        swalAlertConfirmation(
          `Pastikan password sudah sama ?`,
          "Konfirmasi"
        ).then(async (res) => {
          if (res.isConfirmed) {
            authStore.forgotPasswordReset().then(() => {
              if (!authStore.isError) {
                step.value = "complete-forgot";
              } else {
                swalAlertError(
                  `Gagal mengubah password.\nPastikan password alfanumerik dan mengandung minimal 1 simbol.`,
                  "Close"
                );
              }
            });
          }
        });
      } else {
        router.push("/sign-in");
      }
    };

    return {
      authStore,
      onSubmitForgotPassword,
      forgotPassword,
      submitButton,
      step,
      handleChangeInputCode,
      handleConfirmationPasswordInput,
      isPasswordSame,
    };
  },
});
</script>
<style lang="scss" scoped>
.token-input {
  font-size: xx-large;
  text-align: center;
}
.el-input__inner {
  background-color: #000e84 !important;
}
</style>
